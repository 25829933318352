<template>
  <v-container>
    <div class="d-block d-md-flex">
      <AppAside></AppAside>
      <div class="ps-5 page_content grow">
        <v-skeleton-loader v-if="!data_loaded" type="table"></v-skeleton-loader>
        <v-card v-if="data_loaded">
          <v-card-title class="d-flex align-center justify-space-between">
            <div class="d-flex align-center">
              <v-icon x-large>grid_view</v-icon>
              <h1 class="ms-3">סטריפים</h1>
            </div>
            <v-btn
              v-if="$store.getters.permission('blocks create')"
              color="primary"
              to="/block"
              >סטריפ חדש</v-btn
            >
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-data-table
              class="text-center"
              :headers="headers"
              :items="blocks"
              mobile-breakpoint="0"
            >
              <template v-slot:item.status="{ item }">
                <v-chip
                  x-small
                  class="px-2"
                  :color="item.status ? 'success black--text' : 'error'"
                >
                  {{ item.status ? "פעיל" : "לא פעיל" }}
                </v-chip>
              </template>

              <template v-slot:item.count="{ item }">
                {{ item.count || "__" }}
              </template>

              <template v-slot:item.actions="{ item }">

                <v-btn
                  v-if="$store.getters.permission('blocks edit')"
                  :to="`block/${item.id}`"
                  icon
                  small
                >
                  <v-icon small>edit</v-icon>
                </v-btn>
                
                <v-btn
                  small
                  icon
                  v-if="$store.getters.permission('blocks delete')"
                >
                  <v-icon @click="delete_row(item.id)" small>delete</v-icon>
                </v-btn>


              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </div>
    </div>
    <DeleteDialog
      @delete_done="get_data()"
      @close_delete="delete_obj.delete_prop = false"
      :delete_obj="delete_obj"
    ></DeleteDialog>
  </v-container>
</template>

<script>
import DeleteDialog from "@/components/public/delete";

export default {
  name: "blocks",
  data() {
    return {
      form: false,
      data_loaded: false,
      blocks: [],
      delete_obj: {
        delete_prop: false,
        delete_url: "blocks",
        title: "מחק סטריפ",
        id: null,
      },
      headers: [
        {
          value: "name",
          text: "שם סטריפ",
          align: "center",
        },
        {
          value: "sort",
          text: "סדר",
          align: "center",
        },
        {
          value: "block_type",
          text: "סוג סטריף",
          align: "center",
        },
        {
          value: "count",
          text: "מספר שימוש",
          align: "center",
        },
        {
          value: "status",
          text: "סטטוס",
          align: "center",
        },
        {
          value: "actions",
          text: "actions",
          align: "center",
        },
      ],
    };
  },
  components: {
    DeleteDialog,
  },
  methods: {
    get_data() {
      this.$store
        .dispatch("public__request", {
          config: {
            url: "blocks",
            method: "get",
          },
        })
        .then((res) => {
          this.data_loaded = true;
          this.blocks = Object.assign([], res.data.data);
        })
        .catch((err) => {
          console.log(err.response);
        });
    },

    delete_row(id) {
      this.delete_obj.id = id;
      this.delete_obj.delete_prop = true;
    },
  },
  mounted() {
    this.get_data();
  },
};
</script>
